import { ApolloProvider } from '@apollo/client';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'next-themes';
import dynamic from 'next/dynamic';
import { SnackbarProvider } from 'notistack';
import { useHotkeys } from 'react-hotkeys-hook';
import { YMInitializer } from 'react-yandex-metrika';
import client from '../util/apolloClient.ts';

const Header = dynamic(() => import('./Header'));
const Footer = dynamic(() => import('./Footer'));
const Setup = dynamic(() => import('./Setup'));
const SearchModal = dynamic(() => import('./SearchModal.tsx'));

export { client };
export default function CApp({ PrefContext, preferences, setPreferences, MuiTheme, setShowSearchModal, showSearchModal, selectTheme, themeName, showSetup, setShowSetup, Component, pageProps, loaded }) {
  useHotkeys('s,f,ctrl+k,ctrl+p,ctrl+s', () => setShowSearchModal(true));
  useHotkeys('esc', () => {
    setShowSearchModal(false);
    setShowSetup(false);
  });

  return (
    <ApolloProvider client={client}>
      <PrefContext.Provider value={preferences}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={MuiTheme}>
            <ThemeProvider>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}>
                <Header setTheme={(_theme) => selectTheme(_theme)} theme={themeName} setShowSearchModal={setShowSearchModal} />
                <AnimatePresence>
                  {showSetup && (
                    <Setup
                      themeName={themeName}
                      preferences={preferences}
                      setShowSetup={setShowSetup}
                      setPreferences={setPreferences}
                      selectedTheme={themeName}
                      selectTheme={(_theme) => selectTheme(_theme)}
                      close={() => setShowSetup(false)}
                      prefs={preferences}
                      setPrefs={setPreferences}
                    />
                  )}
                </AnimatePresence>
                <div className="app min-h-[calc(100vh-12rem)]">
                  <Component {...pageProps} />
                </div>
                {loaded && <Footer />}
              </SnackbarProvider>
              <SearchModal open={showSearchModal} setOpen={setShowSearchModal} />
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
        {process.env.NODE_ENV === 'production' && <YMInitializer accounts={[69199435]} options={{ webvisor: true, defer: true }} version="2" />}
      </PrefContext.Provider>
    </ApolloProvider>
  );
}
