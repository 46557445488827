import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

// TODO: useless for now
const wsLink = process.browser
  ? new WebSocketLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTIONS_ENDPOINT,
      options: {
        reconnect: false,
      },
    })
  : null;

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const link = process.browser
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink
    )
  : httpLink;

const defaultMerge = {
  merge(existing, incoming, { args: { offset = 0 } }) {
    // Slicing is necessary because the existing data is
    // immutable, and frozen in development.
    const merged = existing ? existing.slice(0) : [];
    for (let i = 0; i < incoming?.length; ++i) {
      merged[offset + i] = incoming[i];
    }
    return merged;
  },
};

const resourcesMerge = {
  merge(existing, incoming, { args: { offset = 0 } }) {
    const merged = existing?.resources ? existing?.resources.slice(0) : [];
    for (let i = 0; i < incoming?.resources?.length; ++i) {
      merged[offset + i] = incoming?.resources[i];
    }
    return { resources: merged, total: incoming?.total || -1 };
  },
};

// TODO Optimize code duplication
const resourcesUpdatesMerge = {
  merge(existing, incoming, { args: { offset = 0 } }) {
    const merged = existing?.updates ? existing?.updates.slice(0) : [];
    for (let i = 0; i < incoming?.updates?.length; ++i) {
      merged[offset + i] = incoming?.updates[i];
    }
    return { updates: merged, total: incoming?.total || -1 };
  },
};
const resourcesReviewsMerge = {
  merge(existing, incoming, { args: { offset = 0 } }) {
    const merged = existing?.reviews ? existing?.reviews.slice(0) : [];
    for (let i = 0; i < incoming?.reviews?.length; ++i) {
      merged[offset + i] = incoming?.reviews[i];
    }
    return { reviews: merged, total: incoming?.total || -1 };
  },
};

export default new ApolloClient({
  ssrMode: typeof window === 'undefined',
  // uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          getResources: { keyArgs: ['types', 'sortBy', 'order', 'query'], ...resourcesMerge },
          getResourceReviews: { keyArgs: ['id'], ...resourcesReviewsMerge },
          getResourceUpdates: { keyArgs: ['id'], ...resourcesUpdatesMerge },
          getPurchases: { keyArgs: ['resourceId'], ...defaultMerge },
        },
      },
    },
  }),
  link,
});
